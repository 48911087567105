import * as React from "react";
import { CircularProgress } from "@material-ui/core";

export const Loading = () => (
  <div
    style={{
      textAlign: "center",
      padding: "5em"
    }}
  >
    <CircularProgress color="primary" />
  </div>
);

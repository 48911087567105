import * as React from 'react';

import { AppLoading } from '../components/AppLoading';
import Auth from '../services/auth';
import StateManager from '../services/manager/StateManager';

export class Logout extends React.Component<{}, {}> {
  componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    window.setTimeout(() => {
      Auth.logout();
    }, 500);
  }

  render() {
    return <AppLoading />;
  }
}

export default class Helper {
  static formatNumber(value: number, decimals = 0) {
    value = parseFloat(value as any);
    return value
      .toFixed(decimals)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  static formatDate(date: any) {
    if (!date) return "";

    if (typeof date !== "object") {
      if (date.indexOf("T") === -1) {
        date = date.replace(/-/g, "/");
      }

      date = new Date(date);
    }

    const prefix = (c: number) => (c < 10 ? `0${c}` : c);

    return `${prefix(date.getDate())}.${prefix(
      date.getMonth() + 1
    )}.${date.getFullYear()}`;
  }

  static formatTimestamp(timestamp: number) {
    return new Date(timestamp * 1000).toLocaleDateString("de-DE");
  }

  static dateToString(date?: Date | null) {
    if (!date) return undefined;
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
      "0" + date.getDate()
    ).slice(-2)}`;
  }

  static formatPrice(value: number, currency?: string) {
    const price = value.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (!currency || ["EUR", "eur", "euro", "Euro"].indexOf(currency) > -1)
      currency = "EUR";

    return `${price} ${currency}`;
  }

  static canViewPage(isLoggedIn: boolean, route: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      "7b5314d8-bfe5-46c0-bd3e-51f078c4a022": ["Tickets"],
      // MGirokonto
      "28e529ac-41b0-4c2a-9121-15d594e32463": [],
      // LGirokonto
      "09cf3fc2-2b76-45dd-b7bc-ec868920a81c": [],
      // XLGirokonto
      "2f3209d5-c091-4d4b-b752-a059e0cc671a": [],
      // XLMGirokonto
      "5236761b-53e1-499d-be9c-7e7d8783fcf3": [],
    };

    return isLoggedIn ? blacklist[accountType].indexOf(route) === -1 : true;
  }

  static canViewRoute(isLoggedIn: boolean, route: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      "7b5314d8-bfe5-46c0-bd3e-51f078c4a022": ["/tickets"],
      // MGirokonto
      "28e529ac-41b0-4c2a-9121-15d594e32463": [],
      // LGirokonto
      "09cf3fc2-2b76-45dd-b7bc-ec868920a81c": [],
      // XLGirokonto
      "2f3209d5-c091-4d4b-b752-a059e0cc671a": [],
      // XLMGirokonto
      "5236761b-53e1-499d-be9c-7e7d8783fcf3": [],
    };

    return isLoggedIn ? blacklist[accountType].indexOf(route) === -1 : true;
  }

  static hasSecurityService(service: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      "7b5314d8-bfe5-46c0-bd3e-51f078c4a022": [
        "kartenunddokumentenschutz",
        "notfallbargeld",
        "turoffnungsservice",
      ],
      // MGirokonto
      "28e529ac-41b0-4c2a-9121-15d594e32463": [
        "kartenunddokumentenschutz",
        "notfallbargeld",
        "turoffnungsservice",
      ],
      // LGirokonto
      "09cf3fc2-2b76-45dd-b7bc-ec868920a81c": [
        "kartenunddokumentenschutz",
        "notfallbargeld",
      ],
      // XLGirokonto
      "2f3209d5-c091-4d4b-b752-a059e0cc671a": [],
      // XLMGirokonto
      "5236761b-53e1-499d-be9c-7e7d8783fcf3": [],
    };

    return blacklist[accountType].indexOf(service) === -1;
  }

  static clickHandler(event: any, history: any) {
    let el = event.target;

    while (el && el !== event.currentTarget && el.tagName !== "A") {
      el = el.parentNode;
    }

    if (el && el.tagName === "A") {
      if (el.target && el.target === "_blank") {
        event.preventDefault();

        this.handleClickoutHint({ url: el.href }, event, history);
      } else {
        return;
      }
    }
  }

  static handleClickoutHint(data: any, e: any, history: any) {
    e.preventDefault();

    history.push({
      pathname: "/clickout",
      state: data,
    });
  }
}

import { API } from "./API";
import NodeRSA from "node-rsa";
import Config from "./config";

class Auth {
  static async login(
    username: string,
    password: string
  ): Promise<true | string> {
    try {
      const encryptedPassword = this.encrypt(password) || password;

      const result = await API.post("auth/login", {
        username,
        password: encryptedPassword,
      });
      const token = result.access_token;

      localStorage.setItem("token", token);
      API.setToken(token);

      window.location.href =
        window.location.pathname === "/login"
          ? "/profil"
          : window.location.href;

      return true;
    } catch (e) {
      if (e.message.indexOf("User is not verified") > -1) return "notVerified";
      return "invalidCredentials";
    }
  }

  static logout() {
    localStorage.removeItem("token");
    API.setToken();
    (window as any).location.href = "/";
  }

  static async checkLogin(): Promise<boolean> {
    const storedToken = localStorage.getItem("token");
    if (!API.token && storedToken) API.token = storedToken;

    if (!API.token) return false;

    try {
      await API.get("auth/checkLogin");
      return true;
    } catch (e) {
      API.setToken();
      return false;
    }
  }

  static isLoggedIn(): boolean {
    return !!API.token;
  }

  static getTokenInfo() {
    if (!API.token) return null;

    const parts = API.token.split(".");
    return JSON.parse(atob(parts[1]));
  }

  static getFKN() {
    const tokenInfo = Auth.getTokenInfo();

    if (!tokenInfo) return null;

    if (tokenInfo.contracts && tokenInfo.contracts.length > 0) {
      return tokenInfo.contracts[0].id;
    } else if (tokenInfo.userInfo && tokenInfo.userInfo._aditoBasicContractId) {
      return tokenInfo.userInfo._aditoBasicContractId;
    }

    return null;
  }

  static encrypt(secret: any) {
    try {
      const publicKey: string = Config.getPublicTransportKey();
      const key = new NodeRSA(publicKey);
      return key.encrypt(secret, "base64");
    } catch (error) {
      return false;
    }
  }
}

export default Auth;

import { Button, PropTypes } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import Auth from '../services/auth';

interface Props {
  color?: PropTypes.Color;
  disableFocusRipple?: boolean;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  href?: string;
  target?: string;
  size?: "small" | "medium" | "large";
  startIcon?: React.ReactNode;
  variant?: "text" | "outlined" | "contained";
  children: any;
  style?: CSSProperties;
  onClick?: (e: any) => void;
}

export const AuthButton = (props: Props) => {
  if (Auth.isLoggedIn()) {
    return <Button {...props}>{props.children}</Button>;
  } else {
    return (
      <Link to={"/login"}>
        <Button color="primary" variant="contained">
          Zum Login
        </Button>
      </Link>
    );
  }
};

import './InfoTooltip.scss';

import { Tooltip } from '@material-ui/core';
import React from 'react';

interface Props {
  infoText: string;
}

export default class InfoTooltip extends React.Component<Props, {}> {
  render() {
    return (
      <span className="InfoTooltip">
        <Tooltip
          arrow
          title={
            <span style={{ fontSize: "0.857rem" }}>{this.props.infoText}</span>
          }
        >
          <img src="/assets/images/info_black.svg" alt="" />
        </Tooltip>
      </span>
    );
  }
}

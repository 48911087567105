import './Dashboard.scss';

import {
  faAngleRight,
  faFileSignature,
  faPlaneArrival,
  faUserCog,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import ShortlinkBox from '../../components/ShortlinkBox/ShortlinkBox';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';

interface Props {
  history: any;
}

interface State {
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  favoriteShops: Shop[];
  loading: boolean;
  user?: User;
}

export default class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      favoriteShops: [],
      loading: true,
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    this.getUser();
    this.fetchCashbackData();

    this.setState({
      loading: false,
    });
  }

  fetchCashbackData = async () => {
    const [
      { confirmed: confirmedCashback, open: pendingCashback },
      cashoutProfile,
      openComplaints,
      favoriteShops,
    ] = await Promise.all([
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.findFavoriteShops(),
    ]);

    this.setState({
      confirmedCashback,
      pendingCashback,
      cashoutProfile,
      openComplaints,
      favoriteShops: Array.isArray(favoriteShops) ? favoriteShops : [],
    });
  };

  async getUser() {
    const user = await UserManager.me();

    this.setState({
      user,
    });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.favoriteShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        favoriteShops: shops,
      });
    };
  }

  getBrowserName() {
    if (
      /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.exec(
        navigator.userAgent
      )
    )
      return 'chrome';

    if (/Firefox\/([0-9.]+)(?:\s|$)/.exec(navigator.userAgent))
      return 'firefox';

    return '';
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard - NEELMEYERplus Vorteilsportal</title>
        </Helmet>

        <div className="Dashboard">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Grid className="onlyMobile">
                <Box>
                  <Headline type="h1">
                    Hallo{' '}
                    {`${
                      Auth.getTokenInfo().userInfo.salutation === 'Frau'
                        ? 'Frau'
                        : 'Herr'
                    } ${Auth.getTokenInfo().userInfo.lastName}`}
                    ,
                  </Headline>

                  <Headline type="h2" color="text" fontRegular>
                    <p>
                      hier finden Sie sämtliche Mehrwertleistungen und Services
                      Ihres Kontos rund um die Uhr. Shoppen Sie zum Beispiel mit
                      exklusiven Rabatten in mehr als 1.300 Onlineshops, buchen
                      Sie Reisen zu Schnäppchenpreisen oder laden Sie sich
                      wichtige Formulare herunter.
                    </p>
                  </Headline>
                </Box>
              </Grid>

              <Grid className="notMobile">
                <Box>
                  <Box style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Headline type="h1">
                      Hallo{' '}
                      {`${
                        Auth.getTokenInfo().userInfo.salutation === 'Frau'
                          ? 'Frau'
                          : 'Herr'
                      } ${Auth.getTokenInfo().userInfo.lastName}`}
                      ,
                    </Headline>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ maxWidth: '80%' }}>
                        <Headline type="h2" color="text" fontRegular>
                          <p>
                            hier finden Sie sämtliche Mehrwertleistungen und
                            Services Ihres Kontos rund um die Uhr. Shoppen Sie
                            zum Beispiel mit exklusiven Rabatten in mehr als
                            1.300 Onlineshops, buchen Sie Reisen zu
                            Schnäppchenpreisen oder laden Sie sich wichtige
                            Formulare herunter.
                          </p>
                        </Headline>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>

              <Box style={{ paddingTop: 0 }}>
                <Headline type="h3" color="text">
                  Ihre Vorteilsnummer: {Auth.getFKN()}
                </Headline>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Cashback Guthaben"
                      link="/transaktionen/uebersicht"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>bestätigt</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontWeight: 'bold' }}>
                              {Helper.formatNumber(
                                this.state.confirmedCashback || 0,
                                2
                              )}{' '}
                              EUR
                            </p>
                          </div>
                        </div>

                        <div className="dataRow">
                          <div className="dataCell">
                            <p>offen</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontWeight: 'bold' }}>
                              {Helper.formatNumber(
                                this.state.pendingCashback || 0,
                                2
                              )}{' '}
                              EUR
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Auszahlungen"
                      link="/auszahlungen"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>ausgezahlt</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontWeight: 'bold' }}>
                              {(this.state.cashoutProfile &&
                                Helper.formatNumber(
                                  this.state.cashoutProfile.paidOutCash +
                                    this.state.cashoutProfile
                                      .externalPaidOutCash,
                                  2
                                )) ||
                                0}{' '}
                              EUR
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Nachbuchungsanfragen"
                      link="/nachbuchungsanfragen"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>offene Anfragen</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontWeight: 'bold' }}>
                              {this.state.openComplaints || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>
                </Grid>
              </Box>

              {this.state.favoriteShops && this.state.favoriteShops.length > 0 && (
                <Grid container spacing={4} style={{ marginTop: '50px' }}>
                  <Grid item xs={12}>
                    <Box alternativeColor>
                      <Headline type="h2">Ihre Cashback-Favoriten</Headline>

                      <Grid container spacing={2}>
                        {this.state.favoriteShops.map((shop: Shop, key) => (
                          <Grid item xs={6} md={3} key={key}>
                            <ShopTile
                              image={shop.logo}
                              buttonLabel="Zum Shop"
                              morePath={`/shop/${shop.id}`}
                              buttonLink={shop.link}
                              isFavorite={shop.isFavorite}
                              onFavoriteChange={this.handleFavoriteChange(shop)}
                              history={this.props.history}
                              shopId={shop.id}
                            >
                              {shop.maximumCashback && (
                                <Fragment>
                                  Bis zu{' '}
                                  <span>
                                    {Helper.formatNumber(
                                      shop.maximumCashback,
                                      2
                                    )}{' '}
                                    {shop.maximumCashbackType}
                                  </span>{' '}
                                  bei {shop.name}
                                </Fragment>
                              )}
                            </ShopTile>
                          </Grid>
                        ))}
                      </Grid>

                      <div
                        style={{
                          textAlign: 'center',
                          padding: '1em',
                          paddingTop: '3em',
                        }}
                      >
                        <Link
                          to={{
                            pathname: '/cashback/shops',
                            state: {
                              filterState: {
                                direction: 'desc',
                                filters: ['favorites'],
                                favorites: true,
                                orderBy: 'popularity',
                                page: 1,
                              },
                            },
                          }}
                        >
                          Alle Cashback-Favoriten anzeigen
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            style={{
                              marginLeft: 10,
                            }}
                          />
                        </Link>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Box>
                <Grid container spacing={2} className="shortlinks">
                  <div className="flex-holder">
                    <ShortlinkBox
                      icon="OlbIconCashbackRadar"
                      title="Cashbackradar"
                      link="/cashbackradar"
                      targetBlank
                    >
                      <Button color="primary" variant="contained">
                        Jetzt herunterladen
                      </Button>
                    </ShortlinkBox>

                    <ShortlinkBox
                      icon={faPlaneArrival}
                      title="Reiseversicherung"
                      href="https://www.olb.de/privatkunden/konten-und-karten/bezahlen-mit-karten/mastercard-gold-visa-card-gold#e8dc012b-5417-4ab2-b6d8-967b2e199d19"
                    >
                      <Button color="primary" variant="contained">
                        Mehr erfahren
                      </Button>
                    </ShortlinkBox>

                    <ShortlinkBox
                      icon={faFileSignature}
                      title="Dokumente & Formulare"
                      link="/dokumente-und-formulare"
                    >
                      <Button color="primary" variant="contained">
                        Jetzt herunterladen
                      </Button>
                    </ShortlinkBox>

                    <ShortlinkBox
                      icon={faUserCog}
                      title="Ihre Daten"
                      link="/ihre-daten"
                    >
                      <Button color="primary" variant="contained">
                        Daten anpassen
                      </Button>
                    </ShortlinkBox>
                  </div>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import React, { Fragment } from "react";

import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import { ContentElements } from "../../interfaces/ContentElements";
import StateManager from "../../services/manager/StateManager";
import Box from "./../../components/Box/Box";
import CmsManager from "./../../services/manager/CmsManager";

interface Props {}

interface State {
  loading: boolean;
  content?: ContentElements;
  isFirefox: boolean;
  isChrome: boolean;
  buttonLabel: string;
  buttonLink: string;
}

export default class CashbackExtension extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      isFirefox: false,
      isChrome: false,
      buttonLabel: "",
      buttonLink: "",
    };
  }

  detectBrowser() {
    this.setState({
      isChrome: !!/(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.exec(
        navigator.userAgent
      ),
      isFirefox: !!/Firefox\/([0-9.]+)(?:\s|$)/.exec(navigator.userAgent),
    });
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const content = await CmsManager.getPageContentsCleaned("cashback-radar");

    this.detectBrowser();

    let buttonLabel = "";
    let buttonLink = "";

    if (this.state.isChrome) {
      buttonLabel = "Extension installieren (Google Chrome)";
      buttonLink =
        "https://chrome.google.com/webstore/detail/neelmeyerplus-bonusradar/clolbdkeklianijjmdpoklgmcffpabif";
    }

    if (this.state.isFirefox) {
      buttonLabel = "Extension installieren (Firefox)";
      buttonLink =
        "https://addons.mozilla.org/de/firefox/addon/neelmeyerplus-bonusradar/";
    }

    this.setState({
      content,
      loading: false,
      buttonLabel,
      buttonLink,
    });
  }

  render() {
    return (
      <Layout>
        <div className="BonusRadar">
          <InnerLayout>
            {this.state.loading && <Loading />}

            {!this.state.loading && (
              <Fragment>
                <div className="onlyMobile">
                  <Box>
                    {this.state.content?.pageTitle && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "Space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                            flexGrow: 1,
                          }}
                        >
                          <Headline type="h1">
                            {this.state.content.pageTitle}
                          </Headline>
                        </div>

                        {this.state.content.pageDescription && (
                          <div style={{ width: "80%" }}>
                            <Headline type="h2" color="secondary">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.content
                                    .pageDescription as string,
                                }}
                              ></p>
                            </Headline>
                          </div>
                        )}
                      </div>
                    )}
                  </Box>
                </div>

                <ImageSlider
                  images={[
                    {
                      src: "/assets/images/heroes/neel-cashback.jpg",
                      contentBox: {
                        headline:
                          (this.state.content?.pageTitle as string) || "",
                        content: this.state.content?.pageDescription as string,
                        button: {
                          label: this.state.buttonLabel,
                          link: this.state.buttonLink,
                        },
                      },
                    },
                  ]}
                />

                {this.state.content &&
                  Object.keys(this.state.content).map((item: string, index) => {
                    if (
                      this.state.content &&
                      item !== "pageTitle" &&
                      item !== "pageDescription" &&
                      item.endsWith("Title")
                    ) {
                      return (
                        <Box
                          alternativeColor
                          style={{
                            marginBottom: 16,
                          }}
                          id={item.slice(0, -5)}
                          key={index}
                        >
                          <div style={{ width: "80%" }}>
                            <Headline type="h2">
                              {this.state.content[item]}
                            </Headline>

                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.content[
                                  item.slice(0, -5)
                                ] as string,
                              }}
                            ></p>
                          </div>
                        </Box>
                      );
                    } else {
                      return "";
                    }
                  })}
              </Fragment>
            )}
          </InnerLayout>
        </div>
      </Layout>
    );
  }
}

export default class StateManager {
  static states: any = {};

  static setState(name: string, state: any) {
    StateManager.states[name] = state;
  }

  static getState(name: string, defaultValue?: any) {
    return StateManager.states[name] !== undefined
      ? StateManager.states[name]
      : defaultValue !== undefined
      ? defaultValue
      : undefined;
  }

  static resetShopFilter() {
    StateManager.states.cashback = {
      filters: [],
      query: "",
      order: "popularity_desc",
      categoryFilter: 0,
    };
  }

  static resetProductsFilter() {
    StateManager.states.pap = {
      mode: "products",
      query: "",
      shops: [],
      products: [],
      productShops: [],
      loading: true,
      page: 1,
      numberOfPages: 0,
      filters: [],
      shopsFilter: "-1",
      order: "priority",
      priceRange: [0, 0],
      priceRangeFilter: [0, 0],
      categories: [],
      categoryFilter: 0,
      specials: [],
      filterState: {},
    };
  }
}

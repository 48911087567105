import React from 'react';
import { Redirect } from 'react-router-dom';

import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { footerItems, hiddenRoutes, navigationItems } from '../../routes';

export default ({ match }: { match: any }) => {
  const allRoutes = [...footerItems, ...navigationItems, ...hiddenRoutes];
  let result = <Redirect to="/404" />;

  allRoutes.forEach((route) => {
    if (
      route.infoPage &&
      route.infoPage.route &&
      route.infoPage.content &&
      route.infoPage.route === match.params.id
    ) {
      const content = route.infoPage.content;
      result = (
        <Layout>
          <div className="InfoPage">
            <InnerLayout>
              <h1 dangerouslySetInnerHTML={{ __html: content.title }}></h1>
              {content.content}
            </InnerLayout>
          </div>
        </Layout>
      );
    }
  });

  return result;
};

import { Button } from "@material-ui/core";
import React from "react";
import { Link, Redirect } from "react-router-dom";

import Box from "../components/Box/Box";
import Headline from "../components/Headline/Headline";
import InnerLayout from "../components/InnerLayout/InnerLayout";
import { Layout } from "../components/Layout/Layout";
import { Loading } from "../components/Loading";
import { Alert } from "../services/alert";
import UserManager from "../services/manager/UserManager";
import { Helmet } from "react-helmet";

interface Props {
  location: any;
}

interface State {
  loading: boolean;
  sent: boolean;
}

export default class VerifyAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      sent: false,
    };
  }

  async componentDidMount() {
    try {
      await UserManager.verify(this.props.location.search.substr(7));
    } catch (e) {
      Alert.error(
        "Konto aktivieren",
        "Fehler beim Aktivieren des Kontos. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support."
      );
      this.setState({ loading: false });
      return;
    }

    this.setState({
      sent: true,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Account aktivieren - NEELMEYERplus Vorteilsportal</title>
        </Helmet>
        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {this.state.sent && (
          <InnerLayout>
            <Box alternativeColor>
              <Box>
                <Headline type="h1">NEELMEYER Konto aktiviert</Headline>

                <Headline type="h2" color="text" fontRegular>
                  Ihr NEELMEYER Konto wurde aktiviert. Sie können sich jetzt mit
                  Ihren Zugangsdaten anmelden.
                </Headline>

                <Link to="/login">
                  <Button color="primary" variant="contained">
                    Zum Login
                  </Button>
                </Link>
              </Box>
            </Box>
          </InnerLayout>
        )}

        {!this.state.loading && !this.state.sent && <Redirect to="/" />}
      </Layout>
    );
  }
}

import './InnerLayout.scss';

import { Container } from '@material-ui/core';
import React, { CSSProperties } from 'react';

interface Props {
  children: any;
  className?: string;
  style?: CSSProperties;
}

export default function InnerLayout(props: Props) {
  return (
    <div
      className={`InnerLayout ${props.className ? props.className : ""}`}
      style={props.style}
    >
      <Container maxWidth="lg">{props.children}</Container>
    </div>
  );
}

import './TravelTile.scss';

import { Button } from '@material-ui/core';
import React from 'react';

import Headline from '../Headline/Headline';

interface Props {
  children?: any;
  image?: any;
  title?: string;
  price?: string;
}

export default function TravelTile(props: Props) {
  return (
    <div className="TravelTile">
      {props.image && (
        <div className="TravelTile-imageWrapper">
          <img src={props.image.url} alt="" />
        </div>
      )}

      <div className="TravelTile-wrapper">
        <div className="TravelTile-main">
          {props.title && (
            <Headline
              type="h3"
              color="primary"
              style={{ flexGrow: 0, margin: 0 }}
            >
              {props.title}
            </Headline>
          )}

          <p
            dangerouslySetInnerHTML={{
              __html: props.children,
            }}
            className="TravelTile-content"
          ></p>

          <div className="TravelTile-priceTag">
            {props.price && (
              <Headline type="h3" color="primary" style={{ margin: 0 }}>
                {props.price}
              </Headline>
            )}

            <a href="#">
              <Button color="primary" variant="contained">
                Telefonischer Reiseservice
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

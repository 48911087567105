import React from "react";
import "./InputWithButton.scss";
import { Paper, InputBase, Button } from "@material-ui/core";

interface Props {
  placeholder?: string;
  value?: string;
  onClick?: any;
  onChange?: any;
  onKeyDown?: any;
  buttonLabel?: string;
}

export default ({
  placeholder,
  buttonLabel,
  onClick,
  onChange,
  onKeyDown,
  value
}: Props) => (
  <Paper elevation={0} className="InputWithButton">
    <InputBase
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
    <Button onClick={onClick} variant="contained" color={"primary"}>
      {buttonLabel || "Jetzt eintragen"}
    </Button>
  </Paper>
);

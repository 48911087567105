import './Layout.scss';

import { createMuiTheme, Divider, Drawer, List, ListItem, ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { footerItems, navigationItems } from '../../routes';
import { Alert } from '../../services/alert';
import Auth from '../../services/auth';
import Navigation from '../Navigation/Navigation';
import Footer from './../Footer/Footer';
import Head from './Head';

interface Props {
  title?: string;
}

interface State {
  isDrawerOpen: boolean;
  loading: boolean;
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#f15c22",
      main: "#f15c22",
      dark: "#f15c22",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0c2340",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1140,
      lg: 1280,
      xl: 1920,
    },
  },
});

export class Layout extends React.Component<Props, State> {
  public navigation = navigationItems;
  public footer = footerItems;

  constructor(props: any) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      loading: false,
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      "Ein Fehler ist aufgetreten",
      "Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers."
    );
  }

  toggleDrawer() {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  }

  closeDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  render() {
    if (!this.state) return;

    const footer = this.footer;

    if (!Auth.isLoggedIn()) {
      footer.splice(4, 1);
    }

    return (
      <ThemeProvider theme={theme}>
        <Head>
          {Auth.isLoggedIn() && <meta name="fkn" content={Auth.getFKN()} />}
        </Head>

        <div className="layout">
          <Drawer
            onBackdropClick={this.closeDrawer}
            anchor="right"
            open={this.state.isDrawerOpen}
          >
            <List>
              {this.navigation.map((item, index) => (
                <ListItem key={"nav-item-" + index} button>
                  <NavLink
                    style={{ width: "100%", margin: "6px 0" }}
                    key={"nav-" + index}
                    activeClassName="active"
                    to={item.link}
                  >
                    {item.title}
                  </NavLink>
                </ListItem>
              ))}

              {Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: "100%", margin: "6px 0" }}
                    activeClassName="active"
                    to={"/profil"}
                  >
                    Profil
                  </NavLink>
                </ListItem>
              )}

              {!Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: "100%", margin: "6px 0" }}
                    activeClassName="active"
                    to={"/login"}
                  >
                    Login
                  </NavLink>
                </ListItem>
              )}

              <Divider />

              {Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: "100%", margin: "6px 0 0" }}
                    activeClassName="active"
                    to={"/abmelden"}
                  >
                    Abmelden
                  </NavLink>
                </ListItem>
              )}
            </List>
          </Drawer>

          <Navigation
            items={this.navigation}
            toggleDrawer={this.toggleDrawer}
          />

          <div className="content-wrapper">{this.props.children}</div>

          <Footer items={this.footer} />
        </div>
      </ThemeProvider>
    );
  }
}

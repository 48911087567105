import "./Dashboard.scss";
import "./Transactions.scss";

import { faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { faCommentAltLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, MenuItem, Select } from "@material-ui/core";
import { uniq } from "lodash";
import queryString from "query-string";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Backlink from "../../components/Backlink/Backlink";
import Box from "../../components/Box/Box";
import DashboardBox from "../../components/DashboardBox/DashboardBox";
import Headline from "../../components/Headline/Headline";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable";
import { CashbackShoppingTransaction } from "../../interfaces/cashbackShoppingTransaction";
import { CashbackTravelTransaction } from "../../interfaces/cashbackTravelTransaction";
import { CashoutProfile } from "../../interfaces/cashoutProfile";
import Shop from "../../interfaces/shop";
import Helper from "../../services/helper";
import CashbackManager from "../../services/manager/CashbackManager";
import StateManager from "../../services/manager/StateManager";

interface Props {
  match: any;
  location: any;
}

interface State {
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  loading: boolean;
  confirmedCashback: number;
  pendingCashback: number;
  favoriteShops: Shop[];
  transactionsFilter: [];
  type: string;
  page: number;
  status: string;
  yearFilter: string;
  years: number[];
  onlineTransactions: CashbackShoppingTransaction[];
  travelTransactions: CashbackTravelTransaction[];
  generalTransactions: GeneralTransaction[];
}

interface GeneralTransaction {
  shopName: string;
  date: any;
  cashOutAmount: number;
  price: number;
  status: string;
}

export default class Transactions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const params = queryString.parse(this.props.location.search) as any;

    this.state = {
      openComplaints: 0,
      confirmedCashback: 0,
      pendingCashback: 0,
      favoriteShops: [],
      transactionsFilter: [],
      page: 1,
      type: params.type || "Alle Einkäufe",
      status: params.status || "Alle Status",
      years: [],
      onlineTransactions: [],
      travelTransactions: [],
      generalTransactions: [],
      yearFilter: "Alle Jahre",
      loading: true,
    };

    this.handleChangeStatusFilter = this.handleChangeStatusFilter.bind(this);
    this.handleChangeTypeFilter = this.handleChangeTypeFilter.bind(this);
    this.handleChangeYearsFilter = this.handleChangeYearsFilter.bind(this);
  }

  handleChangeStatusFilter(event: any) {
    this.setState(
      {
        status: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeTypeFilter(event: any) {
    this.setState(
      {
        type: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeYearsFilter(event: any) {
    this.setState(
      {
        yearFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  async load() {
    this.setState({ loading: true });

    const startDate =
      this.state.yearFilter !== "Alle Jahre"
        ? "01-01-" + this.state.yearFilter
        : "";

    const endDate =
      this.state.yearFilter !== "Alle Jahre"
        ? "31-12-" + this.state.yearFilter
        : "";

    const result = await Promise.all([
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getOnlineTransactions(startDate, endDate),
      CashbackManager.getTravelTransactions(startDate, endDate),
    ]);

    let normalizedOnlineTransactions: any = [];
    let normalizedTravelTransactions: any = [];

    if (result[2]) {
      normalizedOnlineTransactions = [
        ...result[2].items.map((item) => {
          return {
            shopName: item.shopName || "",
            date: item.date || "",
            cashOutAmount: item.cashOutAmount,
            price: item.price || -1,
            status: item.status || "",
          } as GeneralTransaction;
        }),
      ];
    }

    if (result[3]) {
      normalizedTravelTransactions = [
        ...result[3].items.map((item) => {
          return {
            shopName: item.destination || "",
            date: item.departureDate || "",
            cashOutAmount: item.cashOutAmount || -1,
            price: item.price || -1,
            status: item.status || "",
          } as GeneralTransaction;
        }),
      ];
    }

    let generalTransactions = [
      ...normalizedOnlineTransactions,
      ...normalizedTravelTransactions,
    ];

    let years = [
      ...generalTransactions.map((transaction) =>
        new Date(transaction.date).getFullYear()
      ),
    ];

    years = uniq(years);

    // Save filter state
    StateManager.setState("transactions", {
      type: this.state.type,
      page: this.state.page,
      status: this.state.status,
      yearFilter: this.state.yearFilter,
    });

    if (this.state.status !== "Alle Status") {
      normalizedOnlineTransactions = normalizedOnlineTransactions.filter(
        (transaction: any) => transaction.status === this.state.status
      );
      normalizedTravelTransactions = normalizedTravelTransactions.filter(
        (transaction: any) => transaction.status === this.state.status
      );
      generalTransactions = generalTransactions.filter(
        (transaction: any) => transaction.status === this.state.status
      );
    }

    this.setState({
      cashoutProfile: result[0],
      openComplaints: result[1],
      onlineTransactions: normalizedOnlineTransactions,
      travelTransactions: normalizedTravelTransactions,
      years,
      generalTransactions,
      loading: false,
    });
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    this.load();
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Transaktionen - NEELMEYERplus Vorteilsportal</title>
        </Helmet>
        <div className="Transactions">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                <Backlink
                  label="Zurück zur Übersicht"
                  to="/transaktionen/uebersicht"
                />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Headline type="h1">Ihre Transaktionen</Headline>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <div className="tableFilter">
                      <div>
                        <Headline
                          type="h4"
                          style={{ marginBottom: 0, paddingLeft: 16 }}
                        >
                          Kategorie
                        </Headline>

                        <Select
                          className="rounded-select"
                          name="type"
                          value={this.state.type}
                          onChange={this.handleChangeTypeFilter}
                        >
                          <MenuItem value="Alle Einkäufe">
                            Alle Einkäufe
                          </MenuItem>
                          <MenuItem value="cashback">Online Cashback</MenuItem>
                          <MenuItem value="travel">Reiserückvergütung</MenuItem>
                        </Select>
                      </div>

                      <div>
                        <Headline
                          type="h4"
                          style={{ marginBottom: 0, paddingLeft: 16 }}
                        >
                          Status
                        </Headline>

                        <Select
                          className="rounded-select"
                          name="status"
                          value={this.state.status}
                          onChange={this.handleChangeStatusFilter}
                        >
                          <MenuItem value="Alle Status">Alle Status</MenuItem>
                          <MenuItem value="open">Offen</MenuItem>
                          <MenuItem value="confirmed">Bestätigt</MenuItem>
                          <MenuItem value="received">Ausgezahlt</MenuItem>
                          <MenuItem value="canceled">Storniert</MenuItem>
                        </Select>
                      </div>

                      <div>
                        <Headline
                          type="h4"
                          style={{ marginBottom: 0, paddingLeft: 16 }}
                        >
                          Zeitraum
                        </Headline>

                        <Select
                          className="rounded-select"
                          name="years"
                          value={this.state.yearFilter}
                          onChange={this.handleChangeYearsFilter}
                        >
                          <MenuItem value="Alle Jahre">Alle Jahre</MenuItem>

                          {this.state.years.map((year, key) => (
                            <MenuItem key={key} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    {this.state.type === "Alle Einkäufe" && (
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "shopName",
                            label: "Name",
                            textAlign: "left",
                            sortable: true,
                            notCollapseOnMobile: true,
                          },
                          {
                            name: "date",
                            label: "Einkaufsdatum",
                            notCollapseOnMobile: true,
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            sortable: true,
                            textAlign: "left",
                          },
                          {
                            name: "cashOutAmount",
                            label: "Cashback",
                            sortable: true,
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "price",
                            label: "Warenkorbwert",
                            sortable: true,
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            label: "Status",
                            sortable: true,
                            notCollapseOnMobile: true,
                            customBodyRender: (value) => (
                              <div>
                                {value === "confirmed" && "Bestätigt"}
                                {value === "collected" && "Bestätigt"}
                                {value === "canceled" && "Storniert"}
                                {value === "open" && "Offen"}
                              </div>
                            ),
                            textAlign: "right",
                          },
                        ]}
                        data={
                          this.state.generalTransactions &&
                          this.state.generalTransactions
                            .map((transaction: GeneralTransaction) => {
                              return {
                                shopName: transaction.shopName,
                                date: transaction.date,
                                cashOutAmount: transaction.cashOutAmount,
                                price: transaction.price,
                                status: transaction.status,
                                action: "",
                              };
                            })
                            .filter((a) => !!a.shopName)
                        }
                      />
                    )}

                    {this.state.type === "cashback" && (
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "shopName",
                            label: "Shop",
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "date",
                            label: "Einkaufsdatum",
                            sortable: true,
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "cashOutAmount",
                            label: "Cashback",
                            sortable: true,
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "price",
                            label: "Warenkorbwert",
                            sortable: true,
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            label: "Status",
                            sortable: true,
                            notCollapseOnMobile: true,
                            customBodyRender: (value) => (
                              <div>
                                {value === "confirmed" && "Bestätigt"}
                                {value === "collected" && "Bestätigt"}
                                {value === "canceled" && "Storniert"}
                                {value === "open" && "Offen"}
                              </div>
                            ),
                            textAlign: "right",
                          },
                          {
                            name: "action",
                            label: "Aktion",
                            customBodyRender: (value, entry) => (
                              <Link to="/nachbuchungsanfrage">
                                <FontAwesomeIcon icon={faCommentAltLines} />
                              </Link>
                            ),
                            textAlign: "left",
                          },
                        ]}
                        data={this.state.onlineTransactions
                          .map((transaction: CashbackShoppingTransaction) => {
                            return {
                              shopName: transaction.shopName,
                              date: transaction.date,
                              cashOutAmount: transaction.cashOutAmount,
                              price: transaction.price,
                              status: transaction.status,
                              action: "",
                            };
                          })
                          .filter((a) => !!a.shopName)}
                      />
                    )}

                    {this.state.type === "travel" && (
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "shopName",
                            label: "Reiseziel",
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "date",
                            label: "Abreisedatum",
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "cashOutAmount",
                            label: "Cashback",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            sortable: true,
                            textAlign: "right",
                          },
                          {
                            name: "price",
                            label: "Preis",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            sortable: true,
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            label: "Status",
                            notCollapseOnMobile: true,
                            customBodyRender: (value) => (
                              <div>
                                {value === "confirmed" && "Bestätigt"}
                                {value === "collected" && "Bestätigt"}
                                {value === "canceled" && "Storniert"}
                                {value === "open" && "Offen"}
                              </div>
                            ),
                            sortable: true,
                            textAlign: "right",
                          },
                        ]}
                        data={this.state.travelTransactions.map(
                          (transaction: CashbackTravelTransaction) => {
                            return {
                              shopName: transaction.shopName,
                              date: transaction.date,
                              cashOutAmount: transaction.cashOutAmount,
                              price: transaction.price,
                              status: transaction.status,
                            };
                          }
                        )}
                      />
                    )}

                    <Box>
                      <Headline type="h3" color="primary">
                        Sie finden eine Transaktion nicht?
                      </Headline>

                      <p>
                        Bitte beachten Sie, dass es in manchen Fällen bis zu 90
                        Tage dauern kann, bis Ihre Zahlung in der
                        Transaktionsliste erscheint. Sollte die Buchung dennoch
                        nicht angezeigt werden, haben Sie die Möglichkeit eine
                        Reklamation anzufragen.
                      </p>

                      <Link to="/nachbuchungsanfrage">
                        <Button
                          color="primary"
                          variant="contained"
                          size="medium"
                        >
                          <FontAwesomeIcon
                            icon={faCommentAltLines}
                            style={{
                              marginRight: 10,
                            }}
                          />
                          Neue Nachbuchungsanfrage
                        </Button>
                      </Link>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Auszahlungen"
                          link="/auszahlungen"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>ausgezahlt</p>
                              </div>

                              <div className="dataCell">
                                <p>
                                  {this.state.cashoutProfile &&
                                    Helper.formatPrice(
                                      this.state.cashoutProfile.paidOutCash +
                                        this.state.cashoutProfile
                                          .externalPaidOutCash || 0
                                    )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>

                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Nachbuchungsanfragen"
                          link="/nachbuchungsanfragen"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>offene Anfragen</p>
                              </div>

                              <div className="dataCell">
                                <p>{this.state.openComplaints || 0}</p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

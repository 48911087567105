import "./Travel.scss";

import { faAngleRight, faUserShield } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import Box from "../../components/Box/Box";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ServiceTile from "../../components/ServiceTile/ServiceTile";
import ShortlinkBox from "../../components/ShortlinkBox/ShortlinkBox";
import TravelTile from "../../components/TravelTile/TravelTile";
import { ContentElements } from "../../interfaces/ContentElements";
import Shop from "../../interfaces/shop";
import { User } from "../../interfaces/user";
import Auth from "../../services/auth";
import Config from "../../services/config";
import CashbackManager from "../../services/manager/CashbackManager";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";
import CmsManager from "./../../services/manager/CmsManager";
import { Helmet } from "react-helmet";

interface State {
  travelHighlights: Shop[];
  loading: boolean;
  content?: ContentElements;
  user?: User;
  sliderContentTitle: string;
  sliderContent: string;
  travelId: string;
}

interface Props {}

export default class Travel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      travelHighlights: [],
      loading: true,
      sliderContentTitle: "",
      sliderContent: "",
      travelId: "",
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const travelId = Config.getTravelCategoryId();
    const travelHighlights: any = [];

    const content = await CmsManager.getPageContentsCleaned("reisen");

    this.setState({
      travelHighlights: travelHighlights.items,
      travelId,
      content,
      loading: false,
    });

    let sliderContentTitle = "";
    let sliderContent = "";

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });

      if (
        user.userInfo._aditoBasicContractType ===
        "7b5314d8-bfe5-46c0-bd3e-51f078c4a022"
      ) {
        // SGirokonto
        sliderContentTitle = content.reiseslidersTitle as string;
        sliderContent = content.reisesliders as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "28e529ac-41b0-4c2a-9121-15d594e32463"
      ) {
        // MGirokonto
        sliderContentTitle = content.reiseslidermTitle as string;
        sliderContent = content.reisesliderm as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "09cf3fc2-2b76-45dd-b7bc-ec868920a81c"
      ) {
        // LGirokonto
        sliderContentTitle = content.reisesliderlTitle as string;
        sliderContent = content.reisesliderl as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
          "2f3209d5-c091-4d4b-b752-a059e0cc671a" ||
        user.userInfo._aditoBasicContractType ===
          "5236761b-53e1-499d-be9c-7e7d8783fcf3"
      ) {
        // XLGirokonto
        sliderContentTitle = content.reisesliderxlTitle as string;
        sliderContent = content.reisesliderxl as string;
      }
    } else {
      sliderContentTitle = content.reiseslidernichteingeloggtTitle as string;
      sliderContent = content.reiseslidernichteingeloggt as string;
    }

    this.setState({ sliderContentTitle, sliderContent });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.travelHighlights;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        travelHighlights: shops,
      });
    };
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Reise - NEELMEYERplus Vorteilsportal</title>
        </Helmet>
        <div className="Travel">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              {this.state.content &&
                this.state.sliderContentTitle &&
                this.state.sliderContent && (
                  <Grid className="onlyMobile">
                    <Box>
                      <Headline type="h1">
                        {this.state.sliderContentTitle}
                      </Headline>

                      <Headline type="h2" color="text" fontRegular>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.sliderContent,
                          }}
                        ></p>
                      </Headline>

                      <ServiceTile></ServiceTile>
                    </Box>
                  </Grid>
                )}

              <ImageSlider
                images={[
                  {
                    src: "/assets/images/heroes/neel-reise.jpg",
                  },
                ]}
              />

              {this.state.content &&
                this.state.sliderContentTitle &&
                this.state.sliderContent && (
                  <Grid className="notMobile">
                    <Box>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "Space-between",
                          alignItems: "center",
                          flexGrow: 1,
                        }}
                      >
                        <Headline type="h1">
                          {this.state.sliderContentTitle}
                        </Headline>

                        <ServiceTile></ServiceTile>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ maxWidth: "80%" }}>
                          <Headline type="h2" color="text" fontRegular>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.sliderContent,
                              }}
                            ></p>
                          </Headline>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                )}

              <Box>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={4} item>
                    <ShortlinkBox
                      icon="OlbIconTravelCashback"
                      title="Die Cashback-Reisepartner"
                      link={{
                        pathname: "/cashback/shops",
                        state: {
                          filterState: {
                            page: 1,
                            filter: {},
                            categoryFilter: this.state.travelId,
                            orderBy: "popularity",
                            direction: "desc",
                          },
                        },
                      }}
                      buttonText="Zu den Reisepartnern"
                      style={{ paddingTop: 0 }}
                    >
                      <p>
                        Buchen Sie wie gewohnt dort, wo Sie immer buchen und
                        erhalten Sie bis zu 10% Cashback.
                      </p>
                    </ShortlinkBox>
                  </Grid>

                  <Grid xs={12} sm={4} item>
                    <ShortlinkBox
                      icon="OlbIconTravelSearch"
                      title="Reisesuchmaschine"
                      link="/reise/buchung"
                      buttonText="Jetzt Reise finden"
                      style={{ paddingTop: 0 }}
                    >
                      <p>
                        Finden Sie jetzt Ihre Traumreise mit einer Auswahl von
                        180 namhaften Reiseveranstaltern.
                      </p>
                    </ShortlinkBox>
                  </Grid>

                  <Grid xs={12} sm={4} item>
                    <ShortlinkBox
                      icon={faUserShield}
                      title="Versicherungsschutz"
                      href="https://www.olb.de/privatkunden/konten-und-karten/bezahlen-mit-karten/mastercard-gold-visa-card-gold#e8dc012b-5417-4ab2-b6d8-967b2e199d19"
                      buttonText="Mehr Infos"
                      style={{ paddingTop: 0 }}
                    >
                      <p>
                        Sorglos die Welt entdecken: Mit Ihrer Mastercard Gold
                        haben Sie auf Auslandsreisen immer ein umfangreiches
                        Versicherungspaket in der Tasche.
                      </p>
                    </ShortlinkBox>
                  </Grid>
                </Grid>
              </Box>

              {this.state.content &&
                this.state.content.highlight1Title &&
                this.state.content.highlight2Title && (
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} className="crossLinkBox">
                            <TravelTile
                              image={
                                this.state.content.highlight1Files[0] as any
                              }
                              title={
                                this.state.content.highlight1Title as string
                              }
                              price={
                                this.state.content.highlight1Subtitle as string
                              }
                            >
                              {this.state.content.highlight1}
                            </TravelTile>
                          </Grid>

                          <Grid item xs={12} sm={6} className="crossLinkBox">
                            <TravelTile
                              image={
                                this.state.content.highlight2Files[0] as string
                              }
                              title={
                                this.state.content.highlight2Title as string
                              }
                              price={
                                this.state.content.highlight2Subtitle as string
                              }
                            >
                              {this.state.content.highlight2}
                            </TravelTile>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}

              {this.state.content && (
                <Box>
                  <Grid container spacing={2}>
                    {Object.keys(this.state.content).map(
                      (item: string, index) => {
                        if (
                          this.state.content &&
                          item !== "pageTitle" &&
                          item !== "pageDescription" &&
                          item.endsWith("Title") &&
                          item !== "reiseslidernichteingeloggtTitle" &&
                          item !== "reiseslidersTitle" &&
                          item !== "reiseslidermTitle" &&
                          item !== "reisesliderlTitle" &&
                          item !== "highlight1Title" &&
                          item !== "highlight2Title" &&
                          item !== "reisesliderxlTitle"
                        ) {
                          return (
                            <Grid
                              item
                              xs={12}
                              lg={6}
                              key={index}
                              className={
                                item === "ihreganzpersonlichereiseTitle"
                                  ? "crossLinkBox"
                                  : ""
                              }
                            >
                              {item === "ihreganzpersonlichereiseTitle" && (
                                <img
                                  src="/assets/images/teaser/reisesuche.jpg"
                                  alt=""
                                  style={{ width: "100%" }}
                                />
                              )}

                              {item === "buchensiewiegewohntTitle" && (
                                <img
                                  src="/assets/images/teaser/reisecashback.jpg"
                                  alt=""
                                  style={{ width: "100%" }}
                                />
                              )}

                              <Headline type="h2">
                                {this.state.content[item]}
                              </Headline>

                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.content[
                                    item.slice(0, -5)
                                  ] as string,
                                }}
                              ></p>

                              {item === "ihreganzpersonlichereiseTitle" && (
                                <Link
                                  to={{
                                    pathname: "/reise/buchung",
                                  }}
                                  className="twoColorLink moreLink"
                                >
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    style={{
                                      marginRight: 10,
                                      color: "#f15c22",
                                    }}
                                  />
                                  Jetzt Reise buchen
                                </Link>
                              )}

                              {item === "buchensiewiegewohntTitle" && (
                                <Link
                                  to={{
                                    pathname: "/cashback/shops",
                                    state: {
                                      filterState: {
                                        page: 1,
                                        filter: {},
                                        categoryFilter: this.state.travelId,
                                        orderBy: "popularity",
                                        direction: "desc",
                                      },
                                    },
                                  }}
                                  className="twoColorLink moreLink"
                                >
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    style={{
                                      marginRight: 10,
                                      color: "#f15c22",
                                    }}
                                  />
                                  Alle Urlaub & Reise Cashback-Partner
                                </Link>
                              )}
                            </Grid>
                          );
                        } else {
                          return "";
                        }
                      }
                    )}
                  </Grid>
                </Box>
              )}
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import * as React from 'react';

import Box from '../components/Box/Box';
import Headline from '../components/Headline/Headline';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';

export class Error404 extends React.Component<{}, {}> {
  render() {
    return (
      <Layout title="Seite nicht gefunden">
        <div className="Registration">
          <InnerLayout>
            <Box>
              <Headline type="h1">404</Headline>
              <Headline type="h2" color="text" fontRegular>
                Die Seite wurde nicht gefunden
              </Headline>
              <p>Unter dieser Adresse wurde leider keine Seite gefunden.</p>
            </Box>
          </InnerLayout>
        </div>
      </Layout>
    );
  }
}

import './ResponsiveTable.scss';

import { faLongArrowDown, faLongArrowUp } from '@fortawesome/pro-light-svg-icons';
import { faAngleRight, faChevronLeft, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Hidden, MenuItem, Select } from '@material-ui/core';
import React, { Fragment } from 'react';

export interface ResponsiveTableColumn {
  name: string;
  label: JSX.Element | string;
  customBodyRender?: (value: any, entry?: any) => string | JSX.Element;
  notCollapseOnMobile?: boolean;
  textAlign?: "center" | "left" | "right";
  sortable?: boolean;
}

export default function ResponsiveTable({
  columns,
  data,
  options,
  styleType,
  lastRowIsSumRow,
}: {
  columns: Array<ResponsiveTableColumn>;
  data: any[];
  options?: { pagination?: boolean };
  styleType?: "benefits";
  lastRowIsSumRow?: boolean;
}) {
  const [activeRow, setActiveRow] = React.useState(-1);
  const [sortColumn, setSortColumn] = React.useState<ResponsiveTableColumn>();
  const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">(
    "asc"
  );
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const onLabelClick = (column: ResponsiveTableColumn) => {
    if (column.sortable) {
      if (sortColumn?.name === column.name) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      }
      setSortColumn(column);
    }
  };

  const previous = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const next = () => {
    if (page < Math.round(data.length / itemsPerPage)) {
      setPage(page + 1);
    }
  };

  if (sortColumn) {
    data.sort((entityA, entityB) => {
      if (sortDirection === "asc") {
        return entityA[sortColumn.name] === entityB[sortColumn.name]
          ? 0
          : entityA[sortColumn.name] < entityB[sortColumn.name]
          ? -1
          : 1;
      } else {
        return entityA[sortColumn.name] === entityB[sortColumn.name]
          ? 0
          : entityA[sortColumn.name] > entityB[sortColumn.name]
          ? -1
          : 1;
      }
    });
  }

  let filteredData = [...data];
  if (options?.pagination) {
    filteredData = filteredData.slice(
      (page - 1) * itemsPerPage,
      (page - 1) * itemsPerPage + itemsPerPage
    );
  }

  return (
    <Fragment>
      <table
        className={`responsiveTable ${styleType ? styleType : ""} ${
          lastRowIsSumRow ? "sumRow" : ""
        }`}
      >
        <thead>
          <tr>
            {columns.map((column, key) => {
              if (column.notCollapseOnMobile) {
                return (
                  <th
                    className="tableHead"
                    key={key}
                    onClick={() => onLabelClick(column)}
                    style={{ textAlign: column.textAlign || "center" }}
                  >
                    {column.label !== undefined ? column.label : column}{" "}
                    {sortColumn?.name === column.name &&
                      sortDirection === "asc" && (
                        <FontAwesomeIcon icon={faLongArrowUp} />
                      )}
                    {sortColumn?.name === column.name &&
                      sortDirection === "desc" && (
                        <FontAwesomeIcon icon={faLongArrowDown} />
                      )}
                  </th>
                );
              } else {
                return (
                  <Hidden smDown key={key}>
                    <th
                      className="tableHead"
                      onClick={() => onLabelClick(column)}
                      style={{
                        textAlign: column.textAlign || "center",
                      }}
                    >
                      {column.label !== undefined ? column.label : column}{" "}
                      {sortColumn?.name === column.name &&
                        sortDirection === "asc" && (
                          <FontAwesomeIcon icon={faLongArrowUp} />
                        )}
                      {sortColumn?.name === column.name &&
                        sortDirection === "desc" && (
                          <FontAwesomeIcon icon={faLongArrowDown} />
                        )}
                    </th>
                  </Hidden>
                );
              }
            })}

            <Hidden mdUp>
              <th></th>
            </Hidden>
          </tr>
        </thead>

        <tbody>
          {filteredData.map((entry, index) => (
            <Fragment key={"fragement-" + index}>
              <tr className="tableRow">
                {columns.map((column, key) => {
                  if (column.notCollapseOnMobile) {
                    return (
                      <td
                        className="tableCell"
                        style={{ textAlign: column.textAlign || "center" }}
                        key={key}
                      >
                        {column.customBodyRender
                          ? column.customBodyRender(entry[column.name], entry)
                          : entry[column.name]}
                      </td>
                    );
                  } else {
                    return (
                      <Hidden smDown key={key}>
                        <td
                          className="tableCell"
                          style={{ textAlign: column.textAlign || "center" }}
                        >
                          {column.customBodyRender
                            ? column.customBodyRender(entry[column.name])
                            : entry[column.name]}
                        </td>
                      </Hidden>
                    );
                  }
                })}
                {(columns.filter((column) => column.notCollapseOnMobile)
                  .length !== columns.length && (
                  <Hidden mdUp>
                    {(index === filteredData.length - 1 && lastRowIsSumRow && (
                      <td></td>
                    )) || (
                      <td
                        onClick={() =>
                          setActiveRow(activeRow === index ? -1 : index)
                        }
                        style={{ textAlign: "center" }}
                      >
                        {activeRow === index && (
                          <FontAwesomeIcon icon={faMinus} />
                        )}
                        {activeRow !== index && (
                          <FontAwesomeIcon icon={faPlus} />
                        )}
                      </td>
                    )}
                  </Hidden>
                )) || (
                  <Hidden mdUp>
                    <td></td>
                  </Hidden>
                )}
              </tr>

              {activeRow === index && (
                <Hidden mdUp>
                  <tr className="tableRow hiddenRow">
                    <td className="tableCell" colSpan={42}>
                      {columns.map((column, key) => {
                        if (!column.notCollapseOnMobile) {
                          return (
                            <Grid justify="space-around" key={key} container>
                              <Grid xs={6} className="entityLabel" item>
                                {column.label !== undefined
                                  ? column.label
                                  : column}
                              </Grid>
                              <Grid xs={6} className="entityValue" item>
                                {column.customBodyRender
                                  ? column.customBodyRender(entry[column.name])
                                  : entry[column.name]}
                              </Grid>
                            </Grid>
                          );
                        } else {
                          return false;
                        }
                      })}
                    </td>
                  </tr>
                </Hidden>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>

      {options?.pagination && data.length > itemsPerPage && (
        <div className="pagination">
          <div className="itemsPerPage">
            Einträge pro Seite
            <Select
              className="rounded-select"
              value={itemsPerPage}
              onChange={(event: any) => {
                setItemsPerPage(+event.target.value);
                setPage(1);
              }}
              style={{ width: 80, maxWidth: 80, minWidth: 80 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={80}>80</MenuItem>
            </Select>
          </div>

          <div className="paginationControls">
            <div className="previous" onClick={previous}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>

            <div className="next" onClick={next}>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

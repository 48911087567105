import { ContentElements } from '../../interfaces/ContentElements';
import { ContentPage } from '../../interfaces/ContentPage';
import { DownloadLink } from '../../interfaces/downloadLink';
import { API } from '../API';

export default class CmsManager {
  static async getPage(route: string): Promise<ContentPage> {
    return await API.get(`cms/pages/${route}`);
  }

  static async getPageContents(route: string) {
    return await API.get(`cms/pages/${route}/contents`);
  }

  static async getPageContentElement(route: string, elementId: any) {
    return await API.get(`cms/pages/${route}/contents/${elementId}`);
  }

  static async getPageContentsCleaned(route: string): Promise<ContentElements> {
    const pageContents = await API.get(`cms/pages/${route}`);
    const contentElementsClean: { [key: string]: string | DownloadLink[] } = {};

    if (pageContents.active) {
      contentElementsClean.pageTitle = pageContents.title;
      contentElementsClean.pageDescription = pageContents.description;

      if (
        pageContents.contentElements &&
        pageContents.contentElements.length !== 0
      ) {
        pageContents.contentElements.forEach((item: any) => {
          contentElementsClean[
            `${item.slug.replace(/[^\w\s]/gi, "")}Title`
          ] = item.title as string;

          contentElementsClean[
            `${item.slug.replace(/[^\w\s]/gi, "")}Subtitle`
          ] = item.subTitle as string;

          contentElementsClean[
            item.slug.replace(/[^\w\s]/gi, "")
          ] = item.description as string;

          contentElementsClean[
            `${item.slug.replace(/[^\w\s]/gi, "")}Links`
          ] = item.links as DownloadLink[];

          contentElementsClean[
            `${item.slug.replace(/[^\w\s]/gi, "")}Files`
          ] = item.files as any[];
        });
      }
    }

    return contentElementsClean;
  }
}

import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

export const AppLoading = () => (
  <div
    style={{
      textAlign: "center",
      padding: "5em",
      backgroundColor: "#0c2340",
      height: "100vh",
      color: "#ffffff",
      justifyItems: "center",
      alignItems: "center",
      display: "flex",
    }}
  >
    <div
      style={{
        textAlign: "center",
        display: "block",
        width: "100%",
      }}
    >
      <img
        src="/assets/images/logo_weiss.svg"
        alt=""
        width="120"
        height="120"
      />
      <br />
      <CircularProgress
        color="inherit"
        style={{
          marginTop: "4em",
        }}
      />
    </div>
  </div>
);

import { Button, Grid, TextField } from "@material-ui/core";
import React from "react";
import { Redirect } from "react-router-dom";

import Backlink from "../../components/Backlink/Backlink";
import Headline from "../../components/Headline/Headline";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import { User } from "../../interfaces/user";
import { Alert } from "../../services/alert";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";
import Box from "./../../components/Box/Box";
import { Helmet } from "react-helmet";

interface State {
  loading: boolean;
  redirect: boolean;

  user?: User;

  email: string;
  password: string;
  password2: string;

  errors: object;
}

interface Props {}

export default class ChangeCredentials extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,

      email: "",
      password: "",
      password2: "",

      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const user = await UserManager.me();

    this.setState({
      loading: false,
      user,

      email: user.email,
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState({
        [field]: e.target.value,
      } as any);
    };
  }

  isPasswordSecure(password: string) {
    return !!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/);
  }

  validate() {
    const errors = {} as any;
    const state = this.state as any;
    const fields = ["email"];

    fields.forEach((field: string) => {
      if (
        !state[field] ||
        (typeof state[field] === "string" && state[field].trim() === "")
      )
        errors[field] = "Dieses Feld muss ausgefüllt werden";
    });

    if (state.password.trim() !== "" && state.password !== state.password2)
      errors.password2 = "Die Passwörter stimmen nicht überein";

    if (!this.isPasswordSecure(this.state.password))
      errors.password =
        "Bitte geben Sie mindestens acht Zeichen, eine Zahl, einen Großbuchstaben und einen Kleinbuchstaben ein. Sonderzeichen, ausgenommen Leerzeichen, sind erlaubt.";

    this.setState({
      errors,
    });

    return JSON.stringify(errors) === "{}";
  }

  async handleSubmit() {
    if (!this.validate()) return;

    this.setState({ loading: true });

    if (
      this.state.user &&
      this.state.email &&
      this.state.email !== "" &&
      this.state.email !== this.state.user.email &&
      this.state.password &&
      this.state.password.trim() !== ""
    ) {
      await UserManager.updateEmail(this.state.email);
      await UserManager.updatePassword(this.state.password);

      Alert.success(
        "Daten geändert",
        "Ihr Passwort wurde erfolgreich geändert. Zusätzlich erhalten Sie in wenigen Minuten eine Bestätigungs-E-Mail. Bitte klicken Sie auf den Link in dieser E-Mail um die Änderung Ihrer E-Mail-Adresse zu bestätigen."
      );
    }

    if (
      this.state.user &&
      this.state.password &&
      this.state.password === "" &&
      this.state.email !== "" &&
      this.state.email !== this.state.user.email
    ) {
      await UserManager.updateEmail(this.state.email);

      Alert.success(
        "E-Mail verifizieren",
        "Sie erhalten in wenigen Minuten eine Bestätigungs-E-Mail. Bitte klicken Sie auf den Link in dieser E-Mail um die Änderung Ihrer E-Mail-Adresse zu bestätigen."
      );
    }

    if (
      this.state.user &&
      this.state.user.email === "" &&
      this.state.password &&
      this.state.password !== "" &&
      this.state.password.trim() !== ""
    ) {
      await UserManager.updatePassword(this.state.password);

      Alert.success(
        "Passwort geändert",
        "Ihr Passwort wurde erfolgreich geändert."
      );
    }

    this.setState({
      redirect: true,
      loading: false,
    });
  }

  render() {
    const errors = this.state.errors as any;

    if (this.state.redirect) return <Redirect to="/ihre-daten" />;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Zugangsdaten ändern - NEELMEYERplus Vorteilsportal</title>
        </Helmet>
        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && this.state.user && (
          <InnerLayout className="Settings">
            <Box>
              <Backlink label="Zurück zur Übersicht" to="/ihre-daten" />

              <Headline type="h1">Ihre Zugangsdaten</Headline>
            </Box>

            <Box alternativeColor>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="E-Mail-Adresse"
                      variant="filled"
                      autoComplete={"email"}
                      value={this.state.email}
                      onChange={this.handleChange("email")}
                      error={errors.email !== undefined}
                      helperText={errors.email ? errors.email : ""}
                    />
                  </Grid>

                  <Grid item xs={6} />

                  <Grid item xs={6}>
                    <TextField
                      label="Passwort"
                      type="password"
                      variant="filled"
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                      error={errors.password !== undefined}
                      helperText={errors.password ? errors.password : ""}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Passwort wiederholen"
                      type="password"
                      variant="filled"
                      value={this.state.password2}
                      onChange={this.handleChange("password2")}
                      error={errors.password2 !== undefined}
                      helperText={errors.password2 ? errors.password2 : ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSubmit}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}

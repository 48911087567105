import './Pagination.scss';

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';

interface Props {
  currentPage: number;
  numberOfPages: number;
  onPageChange: (page: number) => void;
}

export default function Pagination(props: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const maxPrevNext = matches ? 2 : 3;
  const pagesBefore = [];
  const pagesAfter = [];
  let prevPage = props.currentPage;
  prevPage--;
  let nextPage = props.currentPage;
  nextPage++;
  const lastPage = props.numberOfPages;

  for (let i = prevPage; i > 0 && pagesBefore.length < maxPrevNext; i--) {
    pagesBefore.push(i);
  }

  for (
    let i = nextPage;
    i <= props.numberOfPages && pagesAfter.length < maxPrevNext;
    i++
  ) {
    pagesAfter.push(i);
  }

  return (
    <div className="Pagination">
      <Hidden only={"xs"}>
        {props.currentPage > 1 && (
          <span onClick={() => props.onPageChange(prevPage)}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </span>
        )}
      </Hidden>

      {pagesBefore.indexOf(1) === -1 && props.currentPage > maxPrevNext + 1 && (
        <span onClick={() => props.onPageChange(1)}>{1}</span>
      )}

      {pagesBefore.indexOf(1) === -1 && props.currentPage > 5 && (
        <span className="noHover">...</span>
      )}

      {pagesBefore.reverse().map((page, key) => (
        <span key={key} onClick={() => props.onPageChange(page)}>
          {page}
        </span>
      ))}

      <span className="active">{props.currentPage}</span>

      {pagesAfter.map((page, key) => (
        <span key={key} onClick={() => props.onPageChange(page)}>
          {page}
        </span>
      ))}

      {props.numberOfPages > 1 &&
        pagesAfter.indexOf(lastPage - 1) === -1 &&
        props.currentPage !== lastPage - 1 &&
        props.currentPage !== lastPage && <span className="noHover">...</span>}

      {pagesAfter.indexOf(lastPage) === -1 &&
        props.currentPage < props.numberOfPages && (
          <span onClick={() => props.onPageChange(lastPage)}>{lastPage}</span>
        )}

      <Hidden only={"xs"}>
        {props.currentPage < props.numberOfPages && (
          <span onClick={() => props.onPageChange(nextPage)}>
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        )}
      </Hidden>
    </div>
  );
}

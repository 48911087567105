import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Notification from '../../components/Notification/Notification';
import { ContentElements } from '../../interfaces/ContentElements';
import Shop from '../../interfaces/shop';
import CashbackManager from '../../services/manager/CashbackManager';
import CmsManager from '../../services/manager/CmsManager';

interface Props {
  history: any;
  navigation: any;
}

interface State {
  loading: boolean;
  content?: ContentElements;
  shop?: Shop;
  data: any;
}

export default class ClickoutHint extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      data: undefined,
    };
  }

  async componentDidMount() {
    const data = this.props.history.location.state;

    const content = await CmsManager.getPageContentsCleaned("clickout-hint");

    let shop = undefined;

    if (data.shopId) {
      shop = await CashbackManager.findShop(data.shopId);
    }

    this.setState({
      content,
      data,
      shop: shop,
      loading: false,
    });
  }

  render() {
    const { history } = this.props;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Externe Hinweise - NEELMEYERplus Vorteilswelt</title>
        </Helmet>

        <div className="ClickoutHint">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <Fragment>
              <InnerLayout>
                <Box style={{ paddingBottom: 0 }}>
                  <Backlink to={history.location.pathName} label="Zurück" />
                </Box>

                {this.state.content && (
                  <Box
                    style={{
                      paddingTop: 30,
                      paddingBottom: 0,
                    }}
                  >
                    <Headline type="h2" style={{ marginBottom: 0 }}>
                      {this.state.content?.pageTitle}
                    </Headline>
                  </Box>
                )}

                {this.state.data &&
                  this.state.data.type &&
                  this.state.data.type === "shop" && (
                    <Box style={{ marginTop: 0 }}>
                      <Notification
                        type="warning"
                        title="Wichtig: Alle Cookies akzeptieren!"
                        text="Ihr Cashback kann nur erfasst werden, wenn Sie beim Partner der Verwendung aller Cookies zustimmen."
                      />
                    </Box>
                  )}

                {this.state.content && (
                  <Box>
                    {this.state.data &&
                      this.state.data.type &&
                      this.state.data.type === "shop" && (
                        <Fragment>
                          {this.state.shop && this.state.shop.important && (
                            <Fragment>
                              <Headline type="h1" size="small">
                                Bitte beachten Sie bei {this.state.shop.name}
                              </Headline>

                              <div style={{ width: "80%", marginBottom: 30 }}>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.shop.important,
                                  }}
                                ></p>
                              </div>
                            </Fragment>
                          )}

                          {this.state.content.cashbackshop && (
                            <Fragment>
                              <Headline type="h1" size="small">
                                {this.state.content.cashbackshopTitle}
                              </Headline>

                              <div style={{ width: "80%" }}>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content
                                      .cashbackshop as string,
                                  }}
                                ></p>
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      )}

                    {this.state.data &&
                      this.state.data.type === undefined &&
                      this.state.content.allgemein && (
                        <Fragment>
                          <Headline type="h2" size="small">
                            {this.state.content.allgemeinTitle}
                          </Headline>

                          <div style={{ width: "80%" }}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.content.allgemein as string,
                              }}
                            ></p>
                          </div>
                        </Fragment>
                      )}

                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <a
                        href={this.state.data && this.state.data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: 10 }}
                        >
                          Weiter
                          {this.state.data &&
                          this.state.data.type &&
                          this.state.data.type === "shop"
                            ? " zum Shop"
                            : ""}
                        </Button>
                      </a>
                    </div>
                  </Box>
                )}
              </InnerLayout>
            </Fragment>
          )}
        </div>
      </Layout>
    );
  }
}

import './Login.scss';

import { faKey, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Box from '../components/Box/Box';
import Headline from '../components/Headline/Headline';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';
import Auth from '../services/auth';
import UserManager from '../services/manager/UserManager';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
    display: "block",
    marginBottom: 10,
  },
  adornment: {
    height: "auto",
    width: "auto",
  },
  icon: {
    padding: 10,
    width: "40px!important",
    height: "40px!important",
  },
}));

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const classes = useStyles();

  const handleResendRegisterMail = () => {
    const MySwal = withReactContent(Swal);

    UserManager.resendVerification(username).then(() => {
      MySwal.fire(
        "",
        "Wir haben Ihnen eine neue Bestätigungs-E-Mail gesendet. Bitte schauen Sie in Ihr E-Mail-Postfach bestätigen Sie Ihre Registrierung.",
        "success"
      );
    });
  };

  const login = () => {
    Auth.login(username, password).then((response) => {
      const LoginSwal = withReactContent(Swal);

      if (response === "invalidCredentials") {
        LoginSwal.fire({
          title: "Login fehlgeschlagen",
          text:
            "Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.",
          icon: "error",
          confirmButtonText: "OK",
          footer:
            !!username &&
            username.indexOf("@") !== -1 &&
            username.indexOf(".") !== -1 ? (
              <a onClick={() => handleResendRegisterMail()}>
                Bestätigungs-E-Mail erneut senden
              </a>
            ) : (
              false
            ),
        });
      } else if (response === "notVerified") {
        LoginSwal.fire({
          title: "Login fehlgeschlagen",
          text:
            "Sie haben sich noch nicht verifiziert. Bitte schauen Sie in Ihr E-Mail-Postfach bestätigen Sie Ihre Registrierung.",
          icon: "error",
          confirmButtonText: "OK",
          footer:
            !!username &&
            username.indexOf("@") !== -1 &&
            username.indexOf(".") !== -1 ? (
              <a onClick={() => handleResendRegisterMail()}>
                Bestätigungs-E-Mail erneut senden
              </a>
            ) : (
              false
            ),
        });
      }
    });
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - NEELMEYERplus Vorteilsportal</title>
      </Helmet>
      <InnerLayout className="Login">
        <Grid className="onlyMobile">
          <Box>
            <Headline type="h1">
              Willkommen im NEELMEYER Vorteilsportal
            </Headline>

            <Headline type="h2" color="text" fontRegular>
              <p>
                Mit Ihrem NEELMEYER Konto genießen Sie ein besonderes Plus an
                Lebensqualität und sparen in verschiedenen Lebensbereichen bares
                Geld. Melden Sie sich jetzt an und erkunden Sie all die Vorteile
                Ihres NEELMEYER Kontos. Noch nicht registriert? Dann klicken Sie{" "}
                <Link to="/registrierung" style={{ color: "#f15c22" }}>
                  hier
                </Link>
                .
              </p>
            </Headline>
          </Box>
        </Grid>

        <Grid className="notMobile">
          <Box>
            <Box>
              <Headline type="h1">
                Willkommen im NEELMEYERplus Vorteilsportal
              </Headline>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ maxWidth: "80%" }}>
                  <Headline type="h2" color="text" fontRegular>
                    <p>
                      Mit Ihrem NEELMEYERplus Konto genießen Sie ein besonderes
                      Plus an Lebensqualität und sparen in verschiedenen
                      Lebensbereichen bares Geld. Melden Sie sich jetzt an und
                      erkunden Sie all die Vorteile Ihres NEELMEYERplus Kontos.
                      Noch nicht registriert? Dann klicken Sie{" "}
                      <Link to="/registrierung" style={{ color: "#f15c22" }}>
                        hier
                      </Link>
                      .
                    </p>
                  </Headline>
                </div>
              </div>
            </Box>
          </Box>
        </Grid>

        <Box alternativeColor>
          <Box style={{ padding: "60px 120px 60px 120px" }}>
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              wrap="nowrap"
              style={{ marginBottom: 30 }}
            >
              <Grid item>
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={faUser}
                  style={{ color: "#0c2340" }}
                />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  fullWidth={true}
                  value={username}
                  className={`${classes.fullWidth} loginField`}
                  onChange={(event) => setUsername(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) login();
                  }}
                  label="E-Mail-Adresse"
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              wrap="nowrap"
              style={{ marginBottom: 30 }}
            >
              <Grid item>
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={faKey}
                  style={{ color: "#0c2340" }}
                />
              </Grid>

              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  fullWidth={true}
                  value={password}
                  className={`${classes.fullWidth} loginField`}
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) login();
                  }}
                  label="Passwort"
                  type="password"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item sm={4} xs={12}>
                <NavLink to="/passwort-vergessen">Passwort vergessen?</NavLink>
              </Grid>

              <Grid item sm={8} xs={12}>
                <Typography align="right" className="loginButtons">
                  <Link to="/registrierung" className="buttonRegister">
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginTop: 10 }}
                    >
                      Noch keinen Zugang? Jetzt Registrieren
                    </Button>
                  </Link>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={login}
                    className="submitLogin"
                  >
                    Login Vorteilswelt
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </InnerLayout>
    </Layout>
  );
}

export default Login;

import './ShortlinkBox.scss';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Headline from '../Headline/Headline';
import { ReactComponent as OlbIconCashbackRadar } from './icons/cashback-radar.svg';
import { ReactComponent as OlbIconTravelCashback } from './icons/cashback-reisepartner.svg';
import { ReactComponent as OlbIconTravelSearch } from './icons/reisesuchmaschine.svg';

interface Props {
  children?: any;
  icon?: any;
  title?: string;
  link?: string | { pathname: string; state: { filterState: any } };
  href?: string;
  targetBlank?: boolean;
  style?: CSSProperties;
  buttonText?: string;
  onClick?: any;
}

export default function ShortlinkBox(props: Props) {
  return (
    <Fragment>
      {props.link && (
        <Link
          to={props.link}
          target={props.targetBlank ? "_blank" : ""}
          rel={props.targetBlank ? "noopener noreferrer" : ""}
          style={{
            display: "flex",
            flexGrow: 1,
          }}
          onClick={props.onClick ? props.onClick : null}
          className="shortlinkWrapper"
        >
          <div className="ShortlinkBox" style={{ ...props.style }}>
            {props.icon && typeof props.icon === "string" && (
              <div className="ShortlinkBoxIcon" style={{ marginBottom: 9 }}>
                {props.icon === "OlbIconTravelCashback" && (
                  <OlbIconTravelCashback
                    style={{
                      width: "auto",
                      height: 40,
                    }}
                  />
                )}

                {props.icon === "OlbIconCashbackRadar" && (
                  <OlbIconCashbackRadar
                    style={{
                      width: "auto",
                      height: 40,
                    }}
                  />
                )}

                {props.icon === "OlbIconTravelSearch" && (
                  <OlbIconTravelSearch
                    style={{
                      width: "auto",
                      height: 40,
                    }}
                  />
                )}
              </div>
            )}

            {props.icon && typeof props.icon !== "string" && (
              <div className="ShortlinkBoxIcon">
                <FontAwesomeIcon icon={props.icon as IconName} />
              </div>
            )}

            {props.title && (
              <div className="ShortlinkBoxTitle">
                <Headline type="h2">{props.title}</Headline>
              </div>
            )}

            {props.children && (
              <div className="ShortlinkBoxChildren">{props.children}</div>
            )}

            {props.buttonText && (
              <Button color="primary" variant="contained">
                {props.buttonText}
              </Button>
            )}
          </div>
        </Link>
      )}

      {props.href && (
        <a
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "flex",
            flexGrow: 1,
          }}
          className="shortlinkWrapper"
        >
          <div className="ShortlinkBox" style={{ ...props.style }}>
            {props.icon && typeof props.icon === "string" && (
              <div className="ShortlinkBoxIcon" style={{ marginBottom: 21 }}>
                {props.icon === "OlbIconTravelCashback" && (
                  <OlbIconTravelCashback
                    style={{
                      width: "auto",
                      height: 40,
                    }}
                  />
                )}

                {props.icon === "OlbIconCashbackRadar" && (
                  <OlbIconCashbackRadar
                    style={{
                      width: "auto",
                      height: 40,
                    }}
                  />
                )}

                {props.icon === "OlbIconTravelSearch" && (
                  <OlbIconTravelSearch
                    style={{
                      width: "auto",
                      height: 40,
                    }}
                  />
                )}
              </div>
            )}

            {props.icon && typeof props.icon !== "string" && (
              <div className="ShortlinkBoxIcon">
                <FontAwesomeIcon icon={props.icon as IconName} />
              </div>
            )}

            {props.title && (
              <div className="ShortlinkBoxTitle">
                <Headline type="h2">{props.title}</Headline>
              </div>
            )}

            {props.children && (
              <div className="ShortlinkBoxChildren">{props.children}</div>
            )}

            {props.buttonText && (
              <Button color="primary" variant="contained">
                {props.buttonText}
              </Button>
            )}
          </div>
        </a>
      )}
    </Fragment>
  );
}

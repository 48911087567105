import "./Travel.scss";

import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

import { AuthButton } from "../../components/AuthButton";
import Backlink from "../../components/Backlink/Backlink";
import Box from "../../components/Box/Box";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import { ContentElements } from "../../interfaces/ContentElements";
import { DownloadLink } from "../../interfaces/downloadLink";
import Shop from "../../interfaces/shop";
import { User } from "../../interfaces/user";
import Auth from "../../services/auth";
import CashbackManager from "../../services/manager/CashbackManager";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";
import CmsManager from "./../../services/manager/CmsManager";

interface State {
  cashbackShops: Shop[];
  loading: boolean;
  content?: ContentElements;
  user?: User;
  sliderContent: string | DownloadLink[];
}

interface Props {}

export default class TravelBooking extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cashbackShops: [],
      loading: true,
      sliderContent: "",
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const cashbackShops = await CashbackManager.findShopByCategoryId(
      "13",
      1,
      4
    );

    const content = await CmsManager.getPageContentsCleaned("reisen");

    const sliderContent = content.reiseslidernichteingeloggt;

    this.setState({
      cashbackShops: cashbackShops.items,
      content,
      loading: false,
      sliderContent,
    });

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.cashbackShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        cashbackShops: shops,
      });
    };
  }

  render() {
    const fkn = Auth.getFKN();

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Reisebuchung - NEELMEYERplus Vorteilsportal</title>
        </Helmet>
        <div className="Travel">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading &&
            this.state.content &&
            this.state.sliderContent && (
              <InnerLayout>
                <ImageSlider
                  images={[
                    {
                      src: "/assets/images/heroes/neel-reise.jpg",
                      contentBox: {
                        content: this.state.sliderContent as string,
                      },
                      showServiceTile: true,
                    },
                  ]}
                />

                <Box style={{ paddingBottom: 0 }}>
                  <Backlink
                    label="zurück zu Reise"
                    to="/reise/buchung"
                    style={{ paddingBottom: 0 }}
                  />
                </Box>

                {Auth.isLoggedIn() && (
                  <Box>
                    <Grid container spacing={2}>
                      <iframe
                        src={`https://buchung.reisewerk24.de/search/region?taid=neelmeyer-plus&colors=p-102340,pl-e6e9eb,pd-333333,a-f15c23,al-f4662a,ad-e55b1f,c-f15c23,cd-e55b1f&agent=`}
                        style={{ width: "100%", height: 3000 }}
                        title="ibe"
                      ></iframe>
                    </Grid>
                  </Box>
                )}

                {!Auth.isLoggedIn() && (
                  <Box>
                    <div style={{ position: "relative" }}>
                      <img
                        src="/assets/images/travel-blurred.jpg"
                        alt=""
                        style={{
                          width: "100%",
                        }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          left: 0,
                          top: 100,
                          right: 0,
                        }}
                      >
                        <div className="loginRequired">
                          <Headline type="h2" color="white">
                            Bitte einloggen
                          </Headline>

                          <p style={{ color: "#ffffff" }}>
                            Bitte loggen Sie sich ein, damit Sie sich Ihr
                            Cashback bei der Reisebuchung sichern können.
                            Anschließend haben Sie die Möglichkeit die
                            gewünschte Reise auszuwählen und zu buchen.
                          </p>

                          <AuthButton
                            color="primary"
                            variant="contained"
                            size="medium"
                            style={{
                              alignSelf: "flex-end",
                              marginTop: "2em",
                            }}
                          >
                            Login
                          </AuthButton>
                        </div>
                      </div>
                    </div>
                  </Box>
                )}
              </InnerLayout>
            )}
        </div>
      </Layout>
    );
  }
}

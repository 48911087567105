import { Button, Grid, TextField } from "@material-ui/core";
import React from "react";

import Box from "../components/Box/Box";
import Headline from "../components/Headline/Headline";
import InnerLayout from "../components/InnerLayout/InnerLayout";
import { Layout } from "../components/Layout/Layout";
import { Loading } from "../components/Loading";
import { Alert } from "../services/alert";
import UserManager from "../services/manager/UserManager";
import { Helmet } from "react-helmet";

interface Props {
  location: any;
}

interface State {
  loading: boolean;
  password: string;
  password2: string;
  sent: boolean;
}

export default class SetNewPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      password: "",
      password2: "",
      sent: false,
    };

    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeField(field: string) {
    return (e: any) => {
      this.setState({
        [field]: e.target.value,
      } as any);
    };
  }

  isPasswordSecure(password: string) {
    return !!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/);
  }

  async handleSubmit() {
    if (
      this.state.password.trim() === "" ||
      this.state.password !== this.state.password2
    ) {
      Alert.error(
        "Passwort zurücksetzen",
        "Bitte stellen Sie sicher, dass die eingegebenen Passwörter übereinstimmen!"
      );
      return;
    }

    if (!this.isPasswordSecure(this.state.password)) {
      Alert.error(
        "Passwort zurücksetzen",
        "Bitte geben Sie mindestens acht Zeichen, eine Zahl, einen Großbuchstaben und einen Kleinbuchstaben ein."
      );
      return;
    }

    await this.setState({
      loading: true,
    });

    try {
      await UserManager.verifyResetPassword(
        this.state.password,
        this.props.location.search.substr(7)
      );
    } catch (e) {
      Alert.error(
        "Passwort zurücksetzen",
        "Fehler beim Zurücksetzen des Passworts. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support."
      );
      this.setState({ loading: false });
      return;
    }

    this.setState({
      sent: true,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Neues Passwort setzen - NEELMEYERplus Vorteilsportal</title>
        </Helmet>
        {this.state.sent && (
          <InnerLayout>
            <Box>
              <Headline type="h1">Passwort zurücksetzen</Headline>
              <Headline type="h2" color="text" fontRegular>
                Sie können sich jetzt mit Ihrem neuen Passwort anmelden.
              </Headline>
            </Box>
          </InnerLayout>
        )}

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && !this.state.sent && (
          <InnerLayout>
            <Box>
              <Headline type="h1">Passwort zurücksetzen</Headline>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Passwort"
                    type="password"
                    variant="filled"
                    value={this.state.password}
                    onChange={this.handleChangeField("password")}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Passwort wiederholen"
                    type="password"
                    variant="filled"
                    value={this.state.password2}
                    onChange={this.handleChangeField("password2")}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    Passwort zurücksetzen
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}
